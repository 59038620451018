// extracted by mini-css-extract-plugin
export var a = "nav-module--a--59ffe";
export var aboutOutline = "nav-module--aboutOutline--12fe8";
export var active = "nav-module--active--c494c";
export var contactOutline = "nav-module--contactOutline--9ea54";
export var gridContainer = "nav-module--gridContainer--a7a8c";
export var layoutContainer = "nav-module--layoutContainer--11015";
export var li = "nav-module--li--72cbf";
export var link = "nav-module--link--04f56";
export var nav = "nav-module--nav--dcaef";
export var projectsOutline = "nav-module--projectsOutline--78dce";
export var ul = "nav-module--ul--a74ce";