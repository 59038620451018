// extracted by mini-css-extract-plugin
export var aboutOutline = "about-module--aboutOutline--960cc";
export var activeLink = "about-module--activeLink--897b6";
export var contactOutline = "about-module--contactOutline--06e09";
export var corner = "about-module--corner--eaf77";
export var footerTop = "about-module--footerTop--93ffe";
export var gridContainer = "about-module--gridContainer--671e2";
export var h2 = "about-module--h2--272fc";
export var headshot = "about-module--headshot--b4bf9";
export var imgContainer = "about-module--imgContainer--77ebb";
export var layoutContainer = "about-module--layoutContainer--0817a";
export var link = "about-module--link--3f17d";
export var navLink = "about-module--navLink--da6c3";
export var p = "about-module--p--c9b41";
export var projectsOutline = "about-module--projectsOutline--76f19";
export var sidebar___about = "about-module--sidebar___about--17ee9";